import React from "react"

import Layout from "../components/layout"
import Legal from "../components/marketing/legal"
import SEO from "../components/seo"

const AboutPage = () => (
  <Layout>
    <SEO title="Legal" />
    <Legal/>
  </Layout>
)

export default AboutPage
